import Footer from '../components/Footer/footer';
import HeaderNav from '../components/Header/header';
import classes from './HowToPage.module.css';
import React, { useState } from 'react';
import { SQUAT, DEADLIFT, SETUP, BENCH, CURL, PRESS, ROW } from '../constants';

const HowToPage = () => {
  const [url, setUrl] = useState(SETUP);

  return (
    <div className={classes.howToPage}>
      <HeaderNav />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>RESISTANCE TRAINING HERO how-to</h1>
          <div className={classes.howToBox}>
            <div className={classes.listOfButtons}>
              <button onClick={() => setUrl(SETUP)}>Setup</button>
              <button onClick={() => setUrl(BENCH)}>Bench</button>
              <button onClick={() => setUrl(DEADLIFT)}>Deadlift</button>
              <button onClick={() => setUrl(SQUAT)}>Squat</button>
              <button onClick={() => setUrl(CURL)}>Curls</button>
              <button onClick={() => setUrl(PRESS)}>Press</button>
              <button onClick={() => setUrl(ROW)}>Row</button>
            </div>
            <div>
              <iframe
                src={url}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HowToPage;
