import classes from './footer.module.css';
import { FiMail, FiInstagram } from 'react-icons/fi';

const Footer = () => {
  return (
    <div className={classes.footer}>
      <h1>🇺🇸 VETERAN OWNED 🇺🇸</h1>
      <div className={classes.footerContent}>
        <div className={classes.contact}>
          <ul>
            <li>
              <a
                href='mailto:homegymhero.llc@gmail.com?subject=Home Gym Hero'
                target='_blank'
                rel='noreferrer'
              >
                <FiMail /> Email
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/home_gym_hero22/'
                target='_blank'
                rel='noreferrer'
              >
                <FiInstagram /> Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
