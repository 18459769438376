import Footer from '../components/Footer/footer';
import HeaderNav from '../components/Header/header';
import classes from './SubscribePage.module.css';
const url =
  'https://home-gym-hero.us20.list-manage.com/subscribe?u=6c35c189e83025d2e6aa49540&id=e69801840e';

const SubscribePage = () => {
  return (
    <div className={classes.homePage}>
      <HeaderNav />
      <div className={classes.container}>
        <div className={classes.content}>
          <iframe
            title='signup'
            src={url}
            className={classes.subscribeIframe}
          ></iframe>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SubscribePage;
