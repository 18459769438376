import Footer from '../components/Footer/footer';
import HeaderNav from '../components/Header/header';
import classes from './HomePage.module.css';
import attached from '../assets/attached.jpg';
import closeUp from '../assets/closeUp.jpg';
import inUse1 from '../assets/inUse1.jpg';
import inUse2 from '../assets/inUse2.png';
import whiteBack from '../assets/whiteBack.jpg';
import React, { useState } from 'react';

const HomePage = () => {
  const [photo, setPhoto] = useState(closeUp);
  return (
    <div className={classes.homePage}>
      <HeaderNav />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>Resistance Training Hero</h1>
          <h2>$29</h2>
          <a
            href='mailto:homegymhero.llc@gmail.com?subject=Home Gym Hero'
            target='_blank'
            rel='noreferrer'
          >
            <div className={classes.buyButton}>
              <h3>EMAIL TO PURCHASE</h3>
            </div>
          </a>

          <div className={classes.RTHBox}>
            <div className={classes.photoBox}>
              <div className={classes.mainPhoto}>
                <img src={photo} alt='larger'></img>
              </div>
              <div className={classes.subPhotos}>
                <img
                  onClick={() => setPhoto(whiteBack)}
                  src={whiteBack}
                  alt='display '
                ></img>
                <img
                  onClick={() => setPhoto(attached)}
                  src={attached}
                  alt='attached to resistance band '
                ></img>
                <img
                  onClick={() => setPhoto(closeUp)}
                  src={closeUp}
                  alt='close up attached to barbell'
                ></img>
                <img
                  onClick={() => setPhoto(inUse1)}
                  src={inUse1}
                  alt='RTH in use at top of squat'
                ></img>
                <img
                  onClick={() => setPhoto(inUse2)}
                  src={inUse2}
                  alt='RTH in use at bottom of squat'
                ></img>
              </div>
            </div>
            <div className={classes.narrative}>
              <ul>
                <li>
                  The Resistance Training Hero, RTH, is your tool to improve
                  your training routine. Resistance bands allow you to increase
                  resistance in your lifts where the lift tends to be the
                  easiest. This makes your muscles work through the whole range
                  of movement. The RTH attaches to your barbell just inside of
                  your weights along the sleeve. This allows your hands to
                  freely work without worry of the bands sliding into your
                  hands. The bands can’t move or adjust during movement making
                  the RTH and resistance training safer.
                </li>
                <li>
                  Saves you money on expensive weight plates. The RTH allows you
                  to train harder without adding more weight.
                </li>
                <li>
                  Saves space. The RTH takes up less space than a cell phone and
                  you no longer need a professional platform or lifting rig.
                </li>
                <li>
                  Made of high quality aluminum carabiners and durable paracord.{' '}
                </li>
                <li>
                  Lightweight and portable. Easily put in your gym bag to take
                  to the gym or train from home with the RTH taking up little
                  space in your home gym.
                </li>
                <li>Does not come with resistance bands.</li>
              </ul>
              <h2>LEGAL DISCLAIMER</h2>
              <ul>
                <li>
                  There is risk assumed by individuals who use any type of
                  fitness equipment. It is your responsibility to take proper
                  care of your Home Gym Hero products and use as intended and
                  follow the instructions.  Misuse of the products may result in
                  destruction of the product and lead to injury. By buying and
                  using our products, you understand and agree that you
                  expressly and voluntarily assume the risk of personal injury
                  sustained while participating in such activities whether or
                  not caused by the negligence or other fault of Home Gym Hero
                  including but not limited to equipment malfunction from
                  whatever cause, or any other fault of Home Gym Hero.
                  Additionally, you agree to indemnify, defend and hold Home Gym
                  Hero harmless from any claims, including third-party claims,
                  arising from the use of any product.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
