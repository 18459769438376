// import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SubscribePage from './pages/SubscribePage';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HowToPage from './pages/HowToPage';
function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path='/subscribe'>
            <SubscribePage />
          </Route>
          <Route path='/howto'>
            <HowToPage />
          </Route>
          <Route path='/'>
            <HomePage />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
