import classes from './header.module.css';
import HGHLogo from '../../assets/HGHLogo.png';
import { Link } from 'react-router-dom';

const HeaderNav = () => {
  return (
    <header className={classes.header}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img src={HGHLogo} alt='new logo'></img>
        </div>

        <h1>Home Gym Hero</h1>

        <div className={classes.navLinks}>
          <ul>
            <Link to='/'>
              <li>Home</li>
            </Link>
            <Link to='/howto'>
              <li>HowTo</li>
            </Link>
            <Link to='/subscribe'>
              <li>Subscribe</li>
            </Link>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderNav;
